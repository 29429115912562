/**************************************

      = Panels
        ¬ custom panel
        ¬ cross elem
        ¬ question panel
        ¬ contacts panel
        ¬ pricing panels
        ¬ panel reservation
        ¬ tuning contacts panel

                                     */

/**        ¬ custom panel         **/
.panel {
  position: relative;
  padding: 10px;
  margin-bottom: 0;
  color: #fff;
  background-color: $gray-darker;
  border-width: 0;
  border-radius: 0;
  box-shadow: none;

  &__inner {
    position: relative;
    padding: 40/16 + em 30px 20/16 + em;
    border: 1px solid rgba($gray-lighter, 0.5);
  }

  &__heading {
    padding-bottom: 25/16 + em;
    line-height: 1;
  }

  &__tab-title {
    color: $gray-light;
    margin: 0;
  }

  &__title {
    margin: 0 0 15px;
    line-height: 1;
  }

  &__footer {
    margin-bottom: 20/16 + em;

    p {
      margin-bottom: 0;
    }
  }

  .divider-decor {
    display: inline-block;
    margin: 0;
  }

  &--accent {
    background-color: $brand-default;
  }

  &--dark {
    background-color: $gray-darker;
    color: #fff;
  }

  @include media('<xs') {
    margin-right: -15px;
    margin-left: -15px;
  }
}

/**        ¬ cross elem          **/
.panel-cross {
  display: block;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);

  &:before,
  &:after {
    content: '';
    position: absolute;
    margin-top: 12px;
    width: 100%;
    height: 1px;
    background-color: #fff;
    @extend %transition;
  }

  &:after {
    transform: rotate(0);
  }
}


/**        ¬ question panel         **/
.question-panel {
  background-color: $brand-light;
  margin-bottom: 15px;
  padding: 0;
  color: $text-color;

  &__heading {
    position: relative;
    color: #fff;
    background-color: transparent;
    z-index: 1;

    .panel-tab {
      display: block;
      background-color: $brand-default;
      color: inherit;
      min-height: 56px;
      line-height: 28/20 + em;
      padding: 14px 70px 14px 30px;
      @extend %transition;

      &.collapsed {
        background-color: $gray-darker;

        &:hover {
          background-color: $gray-dark;
        }
      }
    }

    .panel-tab.collapsed > .panel-cross:after {
      transform: rotate(-90deg);
    }
  }

  &__title {
    line-height: 1;
    margin: 0;
  }

  &__body {
    padding: 25/16 + em 30px;
    border: 1px solid $brand-gray;
  }
}

/**        ¬ contacts panel         **/
.contacts-panel {

  &__inner {
    padding: 50/16 + em 50px;
  }
}

/**        ¬ pricing panels         **/
.pricing-panel {
  max-width: 390px;
  margin: 0 auto;

  &__inner {
    position: relative;
    min-height: 560px;
    padding: 70/16 + em 30px 130px;
  }

  .panel__title {
    display: flex;
    justify-content: space-between;
  }

  .panel__footer {
    position: absolute;
    left: 0;
    bottom: 78px;
    width: 100%;
    padding: 0 30px;
    margin: 0;

    .btn {
      width: 100%;
    }
  }
}

.pricing-panels-group {
  margin-right: 0;

  .item {
    padding-right: 0;
  }

  @include media ('<md') {

    .item {
      margin-bottom: 15px;

      &.order-2 {
        clear: both;
      }
    }
  }

  @include media('>md') {
    display: flex;
    align-items: center;

    .item.order-2 {

      .panel > .panel__inner {
        min-height: calc(560px + 30px);
      }
    }
  }
}

/**        ¬ panel reservation         **/
.modal-reservation {
  .modal-dialog {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
  }
  .panel {
    width: 100%;
  }

  .section-header {
    max-width: 750px;
    margin: 0 auto;
  }

  @include media('<xs') {
    .form-group {
      padding: 0;
    }
  }

  @include media('>sm','<lg') {
    .modal-dialog {
      max-width: 750px;
      width: 100%;
    }
  }
}

/**        ¬ tuning contacts panel         **/
.tuning-contacts-panel {
  width: 100%;
  padding: 0;
  z-index: 1;

  .panel__title {
    margin-bottom: 0;
  }

  .panel__inner {
    border-width: 0;
  }

  .contacts-list {
    margin-top: 40/16 + em;
  }
}