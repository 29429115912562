/**************************************
      = Sidebar
        ¬ sidebar panel
        ¬ sidebar panel categories
        ¬ sidebar panel banner
        ¬ sidebar panel finder
        ¬ sidebar panel adjust
        ¬ sidebar form
                                     */

.sidebar {

  @include media('<md') {
    &--md-left {
      margin-top: 50px;
    }

    &--md-right {
      margin-bottom: 50px;
    }
  }

  @include media('<sm') {
    &--sm-right {
      margin-bottom: 50px;
    }
  }
}

/**        ¬ sidebar panel         **/
.sidebar-panel {
  position: relative;
  padding: 10px;
  background-color: $brand-light;

  &:nth-last-child(n+2) {
    margin-bottom: 30px;
  }

  &__inner {
    border: 1px solid $brand-gray;
    padding: 10px 30px 50px;
  }

  h4 {
    margin-top: 40/16 + em;
  }

  .social-list {
    margin-bottom: 0;
  }

  @include media(">lg") {
    &__inner {
      padding: 10px 30px 50px;
    }
  }

  @include media ("<xs") {
    margin-left: -15px;
    margin-right: -15px;
  }
}

/**        ¬ sidebar panel categories         **/
.sidebar-panel-categories {
  .list-category {
    > li {
      > ul {
        display: none;
        margin-top: 5px;
      }

      &.active {
        > a {
          color: $gray-dark;
        }
        > ul {
          display: block;
        }
      }
    }
  }
}

/**        ¬ sidebar panel finder         **/
.sidebar-panel-finder {
  .form-group:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  button[type="submit"] {
    padding: 18px 0;
    margin-top: 30px;
  }
}

/**        ¬ sidebar panel adjust         **/
.sidebar-panel-adjust {
  .form-group {
    margin: 0;
  }
  button[type="submit"] {
    margin-top: 50px;
  }
}

/**        ¬ sidebar banner         **/
.sidebar-banner {
  &__tab-title {
    margin: 0;
    color: #fff;
  }

  .panel__headings {
    h1 {
      margin-bottom: 20/72 + em;
    }
  }

  .panel__inner {
    padding: 100px 15px 80px;
  }
}

/**        ¬ sidebar form         **/
.sidebar-form {
  .label-control {
    color: inherit;
  }
}

.price-slider {
  .row {
    &:before,
    &:after {
      display: none;
    }
    display: flex;
    align-items: center;
    margin: 0 -10px 22px;
  }

  .row > [class^=col] {
    position: relative;
    padding: 0 10px;
  }

  .separate {
    position: absolute;
    top: 50%;
    right: -15px;
  }
}

@include media('<lg') {
  .sidebar {
    font-size: 14px;
  }

  .sidebar-panel {
    &__inner {
      padding: 10px 15px 50px;
    }
  }
}

@include media('>xs', '<md') {
  .sidebar-panel {
    &__inner {
      padding: 10px 30px 50px;
    }
  }
}