/**************************************

      = Testimonials
        ¬ testimonials carousel items
        ¬ testimonials post

                                     */

/**        ¬ testimonials carousel items         **/
.testimonials-panel {
  position: relative;
  padding-top: 50px;

  &__inner {
    padding: 50px 30px 25px;
    background-color: $brand-light;
  }

  &__avatar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100px;
    height: 100px;
    margin: 0 auto;

    img {
      border: 4px solid $brand-gray;
      border-radius: 50%;
      width: 100%;
    }
  }

  &__heading {
    line-height: 1;
  }

  &__title {
    margin: 10px 0;
    line-height: 1;
  }
}


/**        ¬ testimonials post         **/
.testimonials-post {
  padding: 30/16 + em 50/877*100% 1px;
  margin-bottom: 30px;
  border-radius: 1px;
  background-color: $brand-light;

  &__heading {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 15/16 + em;
  }

  &__img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    margin-bottom: 10px;

    img {
      border: 4px solid $brand-gray;
      border-radius: 50%;
      width: 100%;
    }
  }

  &__title,
  .social-list {
    margin-bottom: 0;
  }

  &__date {
    position: absolute;
    top: 0;
    right: 0;
  }
}