/**************************************
      = Custom grid
                                     */

/**         masonry settings column        **/
.grid-sizer {
  min-height: 0;
  padding: 0;
  margin: 0;
}

/**        masonry fix container         **/
.grid-row,
.grid-gallery {
  overflow: hidden;
}

.grid-gallery-link {
  position: relative;
  display: block;

  img {
    width: 100%;
  }
}

.row {
  &.no-pad {
    margin: 0;

    > [class^=col] {
      padding: 0;
    }
  }
}

/**        ¬ reset columns         **/
@include media('<xs') {
  .col-rs {
    float: none !important;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  }
}

/**        ¬ table layout         **/
.table-row {

  @include media('>md') {
    display: table;

    &:after,
    &:before {
      display: none;
    }

    > [class*=col] {
      float: none;
      display: table-cell;
      vertical-align: top;
    }
  }
}