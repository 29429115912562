/**************************************

      = Contacts
        ¬ contacts list
        ¬ maps
        ¬ tuning contacts page

                                     */
/**        ¬ contacts list         **/
.contacts-list {

  a {
    color: inherit;
  }

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 30/16 + em;
  }

  .icon {
    max-width: 1em;
    width: 100%;
    margin-right: 13/60 + em;
    font-size: 60/16 + em;
    text-align: center;
    color: $brand-default;
  }
}


/**        ¬ maps         **/
.contacts-map,
.section-map {
  height: 600px;
}

.contacts-map {
  border: 1px solid $brand-gray;
  margin-bottom: 80/16 + em;
}


/**        ¬ tuning contacts page         **/
.tuning-contacts-content {
  position: relative;
  margin: 80/16 + em 0;

  &.row {
    > [class^=col] {
      padding: 0;
    }
  }

  .panel {
    margin: 0;
  }

  .contacts-map {
    margin: 0;
    border-width: 0;
  }

  @include media ('>md') {
    &.row {
      margin-right: 0;
      margin-left: 0;

      > [class^=col] {
        height: 100%;

        > * {
          height: 100%;
        }
      }
    }

    .map-col {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .contacts-map {
      height: auto;
    }
  }

  @include media('<sm') {
    &.row {
      margin: 0 -15px;
    }
  }
}

