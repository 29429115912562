/**************************************

      = Account
        ¬ panel account
        ¬ address form
        ¬ checkout order
        ¬ account settings

                                     */
/**        ¬ panel account        **/
.panel-account {
  max-width: 475px;
  width: 100%;
  margin: 0 auto;

  button[type='submit'],
  .btn {
    margin-bottom: 44/16 + em;
  }
}

/**        ¬ address form         **/
.address-form {

  section {
    h2 {
      margin-top: 0;
      line-height: 1;
    }
  }

  .order-1 {
    margin-bottom: 30px;
  }

  .order-2 {
    margin-bottom: 20px;
  }

  &-row {
    margin: 0 -10px;

    > [class^=col] {
      padding: 0 10px;
    }
  }

  .btn {
    margin-top: 0;
  }
}

/**        ¬ checkout order         **/
.checkout-order {
  &__panels {
    overflow-x: auto;

    > * {
      min-width: 380px;
      padding: 0 30px;
    }
  }

  &__list {
    background-color: $brand-light;
  }

  &__mod {
    position: relative;
    top: -1px;
    background-color: #fff;
    z-index: 1;
  }

  .order-panel {
    padding: 24/16 + em 0;
    margin: 0;
    border-bottom: 1px solid $border-light;

    .order-descr {

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .media-right {
      padding-left: 0;
      vertical-align: middle;
    }

    .media-object {
      width: 90px;
      text-align: right;
    }

    &-reserv {
      padding: 40/16 + em 0;
    }
  }

  .total-panel {
    padding: 45px 30px;
    margin: 0;
  }

  @include media('>md') {
    .order-panel {
      .media-object {
        width: 135px;
      }
    }
  }

  @include media('<xs') {
    margin: 0 -15px;

    &__panels {
      padding: 0;
    }
  }
}

/**        ¬ account settings         **/
.account-settings {
  &-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    .order {
      &:nth-last-child(n+2) {
        margin-bottom: 50px;
      }

      &--left {
        border: 1px solid $border-light;
        border-width: 0 1px 0 0;
      }
    }

    @include media('<lg') {
      margin: 0;

      .order {
        padding: 0;
        padding-bottom: 50px;

        &--left {
          border-width: 0 0 1px 0;
        }
      }
    }
  }
}

