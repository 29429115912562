// Colors
$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray-light:             #888888;
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$brand-default:          #f33544;
$brand-gray:             #cccccc;
$brand-light:            #fafafa;
$brand-white:            #ffffff;

// border
$border-color: #cccccc;
$border-light: #e5e5e5;
$border-lighter: #e3e3e3;


// themes pallete
$themes-pallete: (
  'default':    #f33544,
  'blue':       #1b96d0,
  'orange':     #ff9900,
  'dark':       #222222,
  'light':      #ffffff
);
@function theme-color($key: 'default') {
  @return map-get($themes-pallete, $key);
}

$skin-default: theme-color('default');
$skin-blue: theme-color('blue');
$skin-orange: theme-color('orange');
$skin-dark: theme-color('dark');
$skin-light: theme-color('light');

// select reverse white and dark
$color-reverse: #fff;

// ROOT PATH
$folder-path: '';

// FONTS PATH
$fa-font-path: $folder-path + "fonts/" !default;

// BACKGROUND PATH DEFAULT
$bg-path: $folder-path + 'images/';

// SPRITE
@mixin sprite-image($sprite) {
  background-image: url(#{$sprite-image});
}
$sprite-image: $folder-path + "images/sprite.png";
