/**************************************

      = Services
        ¬ repair service
        ¬ services list
        ¬ services features
        ¬ section maintenance
        ¬ tuning row service
        ¬ tuning services
        ¬ tuning service details

                                     */

/**        ¬ repair service         **/
.repair-row-service {

  &-list {
    overflow: hidden;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;

      &:after, &:before {
        display: none;
      }
    }
  }

  .item {
    margin-bottom: -1px;
    padding: 30/16 + em 45/1200*100% 25/16 + em;
    border: 1px solid #e5e5e5;
    border-top-width: 0;
    border-left-width: 0;

    h5 {
      white-space: nowrap;
      margin: 0;
    }

    p {
      max-width: 480px;
      margin: 0 auto;
    }

    .divider-decor {
      margin: 1em 0;
    }
  }
}

.repair-row-service,
.services-features {

  h2 {
    margin: 0 auto 20/40 + em;
    line-height: 1;
  }

  .img-wrap {
    max-width: 65px;
    max-height: 65px;
    margin: 0 auto 20/16 + em;

    .icon {
      width: 100%;
      font-size: 6rem;
      vertical-align: top;
      color: $brand-default;

      &:before {
        font-size: inherit;
        margin-left: 0;
        vertical-align: top;
      }
    }
  }
}

/**        ¬ services list         **/
.services-list {
  color: #fff;
  margin-bottom: 40/16 + em;

  &__item {
    padding: 78px 0;
    margin-bottom: 30px;
    text-align: center;
    overflow: hidden;

    &.over-dark:after {
      z-index: 1;
    }

    .expand-title {
      position: relative;
      z-index: 2;
    }
  }

  &__heading {
    display: inline-block;
  }
}

/**        ¬ services features         **/
.services-features {
  overflow: hidden;
  text-align: center;
  margin-bottom: -30/16 + em;

  &__item {
    border-right: 1px solid $border-light;
    margin-bottom: 30/16 + em;
  }

  &__caption {
    margin-bottom: -5px;
  }
}

/**        ¬ tuning row service         **/
.tuning-row-service {
  border-bottom: 1px solid $border-light;

  .row {
    margin: 0;
  }

  [class^=col] {
    padding: 0;
  }

  .service-panel {
    height: 320px;

    [class^=col] {
      height: 100%;
    }

    &--column,
    &--column-bg {
      height: 640px;
    }

    &--column {

      [class^=col] {
        height: 50%;
      }
    }

    &--column-bg {

      .expand-title {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
        z-index: 2;
        color: #fff;
      }

      .service-panel__link {
        @include media('<sm', 'portrait') {
          height: 100vh;
          height: 100vmax;
        }

        @include media('<sm', 'landscape') {
          height: 100vmin;
        }
      }
    }

    &__link {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img {
        max-width: none;
        flex: 0 0 auto;
      }
    }

    &-body {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30/16 + em 15px;

      &__inner {
        max-width: 360px;
        width: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-style: solid;
        border-width: 15px;
        z-index: 3;
      }

      &--left {
        &:before {
          left: -28px;
          border-color: transparent #fff transparent transparent;
        }
      }

      &--right {
        &:before {
          right: -28px;
          border-color: transparent transparent transparent #fff;
        }
      }

      &--bottom {
        &:before {
          top: -28px;
          left: 50%;
          transform: translateX(-50%);
          border-color: transparent transparent #fff transparent;
        }
      }
    }
  }

  @include media('<sm') {
    .service-panel {
      height: auto;

      [class^=col] {
        left: auto;
        right: auto;
        width: 100%;
      }

      img {
        max-width: 100%;
        width: 100%;
      }

      &-body {
        &:before {
          top: 100%;
          left: 50%;
          right: auto;
          margin-top: -1px;
          transform: translateX(-50%);
          border-color: #fff transparent transparent transparent;
        }

        &--bottom {
          &:before {
            top: -28px;
            border-color: transparent transparent #fff transparent;
          }
        }
      }
    }
  }

  @include media('>2540px') {
    .service-panel img {
      width: 100%;
    }
  }
}

/**        ¬ tuning services         **/
.tuning-services {
  margin: 80/16 + em #{-15px};

  .service-panel {

    &.row {
      margin: 0;

      > [class^=col] {
        padding: 0;
      }
    }

    &__body,
    &__link {
      height: 50vh;

      @include media('landscape') {
        height: 70vh;
        height: 50vmax;
      }
      height: 50vmax;
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 640px;
      width: 100%;
      padding: 30px 15px;
      margin: 0 auto;
      background-color: $brand-light;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        margin-top: -1px;
        transform: translateX(-50%);
        border: 15px solid transparent;
        border-top-color: $brand-light;
        z-index: 1;
      }

      &-inner {
        max-width: 430px;
        width: 100%;
      }
    }

    &__link {
      position: relative;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: none;
      }
    }
  }

  @include media('>sm') {
    .service-panel {

      &__body,
      &__link {
        height: 380px;
      }

      &__body {
        position: relative;
        padding: 50px;

        &:before {
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          border: 15px solid transparent;
          border-left-color: $brand-light;
          z-index: 3;
        }

        &--right {
          &:before {
            left: -28px;
            border-left-color: transparent;
            border-right-color: $brand-light;
          }
        }
      }
    }
  }

  @include media('<sm') {
    margin: 0 -15px;

    &.row {
      [class^=col] {
        padding: 0;
      }
    }
  }
}

/**        ¬ tuning service details         **/
.tuning-details {
  &-thumbs {

    &.row {
      margin: 0 -5px;
      margin-bottom: 40/16 + em;

      [class^=col] {
        padding: 0 5px;
      }
    }
  }

  .services-features {

    > .row {
      margin-top: -1px;
    }
    &__item {
      padding: 30px 15px;
      margin-bottom: 0;
      border-top: 1px solid $border-light;
    }

    @include media('<sm') {
      &__item:nth-child(1) {
        padding-top: 0;
      }
    }
  }


  .btn-wrapper {
    max-width: 280px;
    width: 100%;
    margin-top: 50px;

    .btn {
      display: block;
    }
  }

  @include media('<md') {

    .btn-wrapper {
      max-width: 100%;
    }
    .services-features {
      margin-top: 80/16 + em;
    }
  }
}
