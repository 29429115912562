/**************************************

      = Controls
        ¬ btn-controls
        ¬ carousel control
        ¬ carousel track
        ¬ lightbox controls
        ¬ page pagination
        ¬ panel navbar gallery
        ¬ range slider
                                     */

/**        ¬ btn-controls         **/
.btn-control {
  &.prev,
  &.next {
    width: 48px;
    height: 48px;
    color: $color-reverse;
    background-color: $brand-default;
    @extend %isi;

    > .fa {
      font-size: 28px;
      line-height: 48px;
      vertical-align: top;
    }
  }
}

/**        ¬ carousel control         **/
.control-carousel {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 50/16 + em;

  .next {
    order: 2;
  }

  .prev, .next {
    flex-shrink: 0;
  }

  &__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 1;
    padding: 0 5px;

    > div {
      flex: 0 0 70/16 + em;
      padding: 4/16 + em 5/16 + em;

      > span {
        display: block;
        width: 100%;
        height: 10/16 + em;
        background-color: $brand-gray;
        cursor: pointer;
        @extend %transition;

        &:hover {
          background-color: $gray-dark;
        }
      }

      > span:focus,
      &.active > span {
        background-color: $brand-default;
      }
    }
  }

  @include media('<sm') {
    &__dots {
      display: none;
    }
  }

  @include media('<xs') {
    &__dots {
      flex-wrap: wrap;

      > div {
        flex-basis: 45/16 + em;

        > span {
          height: 5px;
        }
      }
    }
  }
}

/**        ¬ carousel track         **/
.carousel-track {
  margin-top: 30/16 + em;

  &__item {
    position: relative;
    display: block;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 4px solid transparent;
      @extend %transition;
      z-index: 3;
    }
  }

  .center {

    .carousel-track__item {
      &:before {
        border-color: $brand-default;
      }
    }

    .img-filter {
      filter: grayscale(0);
    }

    .svg-filter {
      opacity: 0;
    }
  }
}

/**        ¬ lightbox controls         **/
.blueimp-gallery {
  > .close {
    position: absolute !important;
    font: normal 42px/42px $font-regular;
    width: 42px;
    height: 42px;
    top: 0;
    right: 0;
    margin: 16px 16px 0 0;
    padding: 0;
    background-color: $brand-default;
    vertical-align: middle;
    text-shadow: none;
    border-width: 0;
    border-radius: 0;
    opacity: 1;
    @extend %isi;
  }

  .slide-content {
    background-size: cover !important;
  }

  > .slides > .slide-loading {
    background: url("../images/three-dots.svg") no-repeat center;
  }
}

.gallery-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 1em;
  margin-bottom: 1em;
  visibility: hidden;

  .indicator {
    display: flex;
    justify-content: center;

    > li {
      position: relative;
      flex: 0 0 auto;
      width: 100px;
      height: 100px;
      margin: 0;
      border-width: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      background-position: 50%;
      background-size: cover;
      opacity: 1;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid transparent;
      }
    }

    > li:hover,
    > li.active {
      background-color: transparent;
    }
    > li.active {
      &:after {
        border-color: $brand-default;
      }
    }
  }

  .btn {
    height: 42px;
    padding: 13px 16px;
    text-align: center;
    white-space: nowrap;
    @extend %isi;

    .icon {
      vertical-align: middle;
      line-height: 1;
      color: inherit;
    }

    &:hover {
      &:before {
        transform: scale3d(8.5, 8.5, 1) !important;
      }
    }
  }

  .btn-text {
    display: none;
    color: inherit;

    @include media('<xs') {
      text-indent: -9999px;
    }
  }

  .play-pause {
    position: static;
    opacity: 1;
  }
  .play {
    display: block;
  }
  .pause {
    display: none;
  }
}

.blueimp-gallery-controls {
  .gallery-controls {
    visibility: visible;
  }
  .btn-text {
    display: inline-block;
  }
}

.blueimp-gallery-playing {
  .play {
    display: none;
  }
  .pause {
    display: block;
  }
}

.scrollbar {
  width: 100%;
  margin: 0 0 1em 0;
  height: 2px;
  background: #fff;
  line-height: 0;
  opacity: .9;
}
.scrollbar .handle {
  width: 100px;
  height: 100%;
  background: $gray-darker;
  cursor: pointer;
}
.scrollbar .mousearea {
  position: absolute;
  top: -9px;
  left: 0;
  width: 100%;
  height: 20px;
}


/**        ¬ page pagination         **/
.pagination-page {
  margin: 50px 0 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: $brand-gray;
}

.pagination {
  display: block;
  width: 100%;
  padding-left: 0;
  margin: 0;
  font-size: 20/16 + em;
  border-width: 0;
  border-radius: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-align: center;

  > li {
    display: inline-block;
    margin-left: -0.24em;

    > a,
    > span {
      float: none;
      display: block;
      position: relative;
      padding: 0 17px;
      height: 46px;
      line-height: 46px;
      color: $brand-default;
      background-color: transparent;
      border-width: 0;
      @extend %transition;
    }

    .fa {
      line-height: 46px;
      vertical-align: top;
    }
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: $gray-dark;
      background-color: transparent;
      border-color: transparent;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 3;
      color: $gray-dark;
      background-color: transparent;
      border-color: transparent;
      cursor: default;
    }
  }
}

/**        ¬ panel navbar gallery         **/
.panel-nav-gallery {
  background-color: $gray-darker;
  display: flex;
  justify-content: center;
}

.gallery-filter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px 15px 51px 16px;
  margin: 0;

  > li {
    flex-grow: 1;
    width: 100%;
    margin-left: -1px;
    margin-bottom: -1px;
    padding: 0;
  }

  > li > a {
    position: relative;
    display: block;
    padding: 17px 15px;
    font: normal 1em/1 $font-raleway;
    text-transform: uppercase;
    color: $brand-default;
    letter-spacing: $ls-regular;
    border: 1px solid $brand-default;
    z-index: 0;
    @extend %transition;

    &:hover {
      color: $text-color;
      background-color: #fff;
      border-color: #fff;
      z-index: 1;
    }

    &:focus,
    &:active {
      color: #fff;
      background-color: $brand-default;
      border-color: $brand-default;
    }
  }

  @include media('>sm') {
    width: auto;

    > li {
      width: auto;

      > a {
        padding: 17px 50px;
      }
    }
  }
}

/**        ¬ range slider         **/
.price-slider {
  .noUi-target {
    height: 4px;
    background: $brand-gray;
    border-radius: 0;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
  .noUi-handle {
    &:before,
    &:after {
      display: none;
    }
    width: 18px;
    height: 18px;
    top: -7px;
    left: -9px;
    border: 4px solid $brand-default;
    border-radius: 50%;
    background: #fff;
    box-shadow: none;
    cursor: pointer;
  }
  .noUi-connect {
    background: $brand-default;
    box-shadow: none;
    transition: background 300ms;
    cursor: pointer;
  }
}