/**************************************

      = Components
        ¬ thumbnail overlay
        ¬ list default
        ¬ list overview
        ¬ contacts list regular
        ¬ social list
        ¬ video
        ¬ tooltips
        ¬ animation settings
        ¬ over catalog
        ¬ animation hover
        ¬ transition regular
        ¬ isi effect for buttons
        ¬ raiting stars
                                     */

//        ¬ thumbnail overlay         **/
%thumb-over {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $brand-default;
    opacity: 0;
    @extend %transition;
  }

  &:hover {
    &:after {
      opacity: .8;
    }
  }
}

/**        ¬ list default title         **/
.list-type {
  &__title {
    margin-bottom: 0;
    line-height: inherit;
  }
}

/**        ¬ list default         **/
.list-default {
  margin-bottom: 12/16 + em;

  > li {
    position: relative;
    padding-left: 16px;
    margin-bottom: 8/16 + em;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-top: 12/16 + em;
      background-color: $brand-default;
    }

    > a {
      display: inline;
      border-bottom: 1px solid;
      color: $brand-default;
      @extend %transition;

      &:hover {
        color: $gray-dark;
      }

      &:focus {
        color: $gray-light;
      }
    }
  }

  &--accent {
    li:before {
      background-color: #fff;
    }
  }
}


/**        ¬ list overview         **/
.list-overview {
  > li {
    &:nth-last-child(n+2) {
      margin-bottom: 10px;
    }

    a {
      @extend .link-accent;
      white-space: nowrap;
    }
  }

  @include media('<md') {
    > li {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  &--block {
    max-width: 180px;
    width: 100%;

    > li {
      white-space: nowrap;
    }
  }
}


/**        ¬ list-category         **/
.list-category {
  margin: -7px 0;

  > li {
    padding: 7px 0;
    margin-bottom: 0;

    &:nth-child(n+2) {
      border-top: 1px solid $border-lighter;
    }

    > a {
      text-decoration: none;
      color: $brand-default;
      @extend %transition;

      &:hover {
        color: $gray-dark;
      }

      &:focus {
        color: $gray-light;
      }
    }
  }

  &.under {
    > li > a {
      display: inline;
      border-bottom: 1px solid;
    }
  }
}

/**        ¬ contacts list regular         **/
.contacts-list-regular {
  margin-bottom: 20/16 + em;

  > li {
    position: relative;
    padding-left: 16px;
    margin-bottom: 8/16 + em;

    .icon {
      position: absolute;
      left: -3px;
      font-size: 14/16 + em;
      line-height: 28/14 + em;
      vertical-align: top;
      color: $brand-default;
    }

    > a {
      color: inherit;
    }
  }

  &.-white {
    color: #f1f1f1;
  }
}

/**        ¬ social list         **/
.social-list {
  margin-right: -6px;
  margin-bottom: 6px;
  font-size: 0;
  line-height: 1;

  > li {
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  > li a {
    display: block;
    font-size: 16px;
    color: $brand-default;
    @extend %transition;

    .fa:last-child {
      color: $color-reverse;
      @extend %transition;
    }

    &:hover,
    &:focus {
      color: $gray-dark; // #333

      .fa:last-child {
        color: #fff;
      }
    }
  }
}

/**        ¬ video         **/
.video-contain {
  position: relative;
  max-height: 500px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .embed-responsive {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;

    &:after {
      content: '';
      display: block;
      height: 0;
      padding-bottom: 56.25%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    @extend %transition;
  }

  &.hover {
    .video-contain__bg {

      &:after {
        background-color: $brand-default;
      }
    }
  }

  &.active {
    .video-contain__bg {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.btn-play {
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 10;

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -22px 0 0 -10px;
    border-style: solid;
    border-width: 22px 28px;
    border-color: transparent transparent transparent $brand-default;
    @extend %transition;
  }
}

/**        ¬ tooltips         **/
.tooltip {
  $tooltip-arrow-width:         7px;
  opacity: 0;

  &-inner {
    padding: 8px 15px;
    font-size: 16px;
    color: $color-reverse;
    text-align: center;
    background-color: $gray-darker;
    border-radius: 0;
  }

  &.top {
    padding: $tooltip-arrow-width 0;
  }

  &.top .tooltip-arrow {
    margin-left: -$tooltip-arrow-width;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    border-top-color: $gray-darker;
  }

  &.bottom .tooltip-arrow {
    margin-left: -$tooltip-arrow-width;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    border-bottom-color: $gray-darker;
  }
}

/**        ¬ animation settings        **/
.over-hover-link,
.over-fade-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 2;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0s ease .6s;

  .icon {
    position: absolute;
    font-size: 52px;
    top: 120%;
    left: 50%;
    color: $color-reverse;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: 1;
  }
}

.over-hover-link {
  .over {
    display: block;
    position: absolute;
    top: 100%;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: rgba($brand-default, .8);
    transition: all 0.3s ease;
  }

  .icon {
    transition: opacity .3s ease 0s, top .3s ease .10s;
  }
}

.over-fade-link {
  opacity: 0;
  background-color: rgba($brand-default, .8);
  transition: all 0.3s;

  .icon {
    transition: opacity 0.6s ease 0s, top 0.6s ease 0s;
  }
}

.expand-title {
  position: relative;
  top: 0;
  opacity: 1;
  transition: opacity 0.6s ease 0.2s, top 0.6s ease 0s;
}

/**        ¬ over catalog         **/
.over-catalog {
  position: absolute;
  top: 0;
  left: -9999px;
  width: 100%;
  height: 100%;
  background-color: rgba($brand-white, .7);
  opacity: 0;
  z-index: 2;
  transition: opacity 300ms, top 300ms, left 0s linear 300ms;

  &__panel {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transform: translateY(-50%);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.6s ease 0s, top 0.6s ease 0s;

    > li {
      margin: 5px 10px;
    }

    > li > a {
      display: block;
      border-radius: 50%;
      width: 42px;
      height: 42px;
      font-size: 20px;
      line-height: 40px;
      background-color: $brand-default;
      text-align: center;
    }

    .icon {
      vertical-align: middle;
      color: #fff;
    }
  }
}

/**        ¬ animation hover         **/
.hover-anim {
  overflow: hidden;
  position: relative;

  &:hover {
    .expand-title {
      top: -150px;
      opacity: 0;
      transition: opacity 0.3s ease 0s, top 0.6s ease 0s;
    }

    .flag-date {
      background-color: $gray-dark;

      &:after {
        border-color: $gray-dark;
      }
    }

    .over-fade-link {
      opacity: 1;

      .icon {
        top: 50%;
        opacity: 1;
      }
    }

    .over-hover-link {
      opacity: 1;
      transition: opacity 0s ease 0s;

      .icon {
        top: 50%;
        opacity: 1;
        transition: opacity .3s ease .10s, top .3s ease 0s;
      }

      .over {
        top: 0;
        left: 0;
      }
    }

    .over-catalog {
      opacity: 1;
      left: 0;
      transition: opacity 300ms ease 0s, top 300ms ease 0s;

      &__panel {
        top: 50%;
        opacity: 1;
      }
    }
  }
}

/**        ¬ transition regular         **/
%transition {
  @include transition-regular();
}

/**        ¬ isi effect for buttons         **/
%isi {
  position: relative;
  overflow: hidden;
  color: $color-reverse;

  > * {
    position: relative;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin: -8px 0 0 1px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $gray-dark;
    z-index: 1;
    transform-origin: 100% 50%;
    transform: scale3d(1, 2, 1);
    transition: transform 0.19s;
    transition-timing-function: cubic-bezier(0.7,0,0.9,1);
  }

  &:hover {
    color: #fff;

    &:before {
      transform: scale3d(5, 5, 1);
    }
  }
}

/**        ¬ raiting stars         **/
.br-theme-fontawesome-stars .br-widget {
  height: auto;
  line-height: 1;
}
.br-theme-fontawesome-stars .br-widget a {
  font: normal normal normal 18px/1 FontAwesome;
  margin-right: 8px;
}

.br-theme-fontawesome-stars .br-widget a:after {
  content: '\f005';
  @extend %transition;
}

/**        ¬ raiting stars         **/
.br-theme-fontawesome-stars .br-widget a:after {
  color: $gray-base;
}
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: $brand-default;
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: $brand-default;
}