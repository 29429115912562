/**************************************

      = Catolog elements
        ¬ panel cart
        ¬ cart product
        ¬ media footer
        ¬ cost-row
        ¬ media counter
        ¬ navbar categories
        ¬ retailer home catalog
        ¬ catalog item panel
        ¬ catalog grid & list
        ¬ catalog front page
        ¬ catalog details
        ¬ catalog compare
        ¬ product info
        ¬ catalog search switcher
        ¬ catalog sort panel
        ¬ refine search
        ¬ control bar
        ¬ catalog pagination
                                     */

/**        ¬ panel-cart         **/
.panel-cart {
  &-list {
    margin-bottom: 20px;

    > li {
      &:nth-last-of-type(n+2) {
        border-bottom: 1px solid $border-lighter;
      }
    }
  }

  &-total {
    padding: 8px 0;
    margin-bottom: 30px;
    border: 1px solid $border-lighter;
    border-width: 1px 0;

    .h3 {
      margin-bottom: 0;
    }
  }
}

/**        ¬ cart product         **/
.cart-product {
  padding: 10px 0;

  .media {
    margin-bottom: 10/16 + em;
    overflow: visible;

    > * {
      vertical-align: middle;
    }

    p {
      margin-bottom: 0;

      &:nth-last-of-type(n+2) {
        margin-bottom: 10/16 + em;
      }
    }
  }

  .media-footer {
    margin-bottom: 0;
  }

  .media-left {
    padding-right: 15px;
  }

  .img-wrap {
    vertical-align: middle;
  }

  .media-object {
    width: 64px;
    height: 64px;
  }
}


/**        ¬ media footer         **/
.media-footer {
  .media-left.cost-row {
    width: 50%;
  }

  .media-right.cost-row {
    text-align: right;
  }
}

/**        ¬ cost-row         **/
.cost-row {

  span.small {
    margin-top: 5px;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
  }
}

.cost-current {
  color: $brand-default;
}
.cost-special {
  position: relative;
  padding: 0 3px;
  margin-left: 15px;
  vertical-align: middle;
  color: $gray-light;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $brand-default;
  }
}

.cost-current,
.cost-total {
  font-size: 20/16 + em;
  font-weight: 700;
  vertical-align: middle;
}

/**        ¬ media counter         **/
.media-counter {
  display: flex;
  align-items: center;

  .order-1 {
    order: -1;
  }

  &--right {
    justify-content: flex-end;
  }

  .btn-close {
    margin-right: 15px;
  }

  .form-control-count {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 8px;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    border: 1px solid $brand-gray;
    @extend %transition;
  }
}

/**        ¬ navbar categories         **/
.navbar-panel {
  box-shadow: none;

  &.navbar-accent {
    .navbar-header {
      background-color: $brand-default;
    }
  }

  .navbar-header {
    .navbar-toggle {
      padding: 18px 15px;
      margin: 0;
      margin-right: 10px;
      border-radius: 0;

      .icon-bar {
        background-color: #fff;
      }
    }
  }

  .navbar-collapse {
    padding: 0;
    border-width: 0;
  }

  .navbar-nav {
    position: relative;
    width: 100%;
    margin: 0;

    .dropdown {
      position: static;

      > a {
        padding-top: 27/20 + em;
        padding-bottom: 27/20 + em;
        font-size: 20/16 + em;
        line-height: 1;
        font-family: $font-regular;
        font-weight: 700;
        letter-spacing: normal;
        text-transform: none;
      }

      .fa {
        margin-top: 1px;
        text-align: right;
      }

      &.open {
        z-index: 1001;
      }
    }
    .dropdown-menu {
      width: 100%;

      > li {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
      }

      .list-default {
        flex-shrink: 0;
        width: 190px;
        padding: 0 15px;
        margin-bottom: 0;
      }
    }
  }

  @include media('>sm') {
    margin: 0;

    .navbar-header {
      display: none;
    }
    .collapse {
      margin: 0 -15px;
    }
    .navbar-nav {
      .dropdown-menu {
        left: 15px;
        width: calc(100% - 30px);
      }
    }
  }

  @include media('>md') {
    .navbar-nav {

      .dropdown {
        position: relative;

        .dropdown-menu {
          min-width: 420px;
        }
      }
    }
  }
}

/**        ¬ retailer home catalog         **/
.catalog-row {
  &:before,
  &:after {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;

  > [class^=col] {
    margin-bottom: 30px;
  }

  &-wrapper {
    margin-bottom: -30px;

    .pagination-page,
    .btn-wrapper {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  @include media('<lg') {
    margin: 0 -10px;

    > [class^=col] {
      padding: 0 10px;
    }
  }
}

/**        ¬ catalog item panel        **/
.catalog-item-panel {
  background-color: $brand-light;
  width: 100%;

  &__img {
    position: relative;
    display: table;
    max-height: 263px;
    margin-bottom: 24/16 + em;
    background-color: #fff;

    img {
      display: table-cell;
      width: 100%;
      vertical-align: middle;
    }
  }

  &__body {
    padding: 0 30px 30px;

    p {
      min-height: 112/16 + em;
    }
  }

  .btn {
    max-width: 200px;
    width: 100%;
    margin-top: 15px;
    padding-left: 0;
    padding-right: 0;
  }

  @include media('<lg') {
    &__body {
      font-size: 14px;
      font-size: 1.4rem;
    }
  }

  @include media('<sm') {
    .btn {
      max-width: 100%;
    }
  }
}

/**        ¬ catalog grid & list        **/
.catalog-list-item {
  width: 100%;

  .catalog-item-panel {
    display: flex;
    align-items: stretch;
    max-width: none;
    height: 263px;
    margin: 0;
    overflow-y: hidden;
    border: 1px solid $border-color;
    @extend %transition;

    &__img {
      display: block;
      padding: 0;
      margin: 0;
      width: 263px;
      overflow: hidden;
      flex-shrink: 0;
      @extend %transition;

      img {
        width: 263px;
        height: 263px;
        max-width: none;
        border-width: 0;
      }
    }

    &__body {
      width: 450px;
      overflow: auto;
      padding: 51px 30px;
      flex-grow: 2;
      @extend %transition;

      p {
        min-height: auto;
      }
    }

    .media-footer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .order-1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 2px;
      }
    }

    .media-body {
      width: auto;
      flex-shrink: 0;
    }

    .btn {
      margin-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    &:hover {
      border-color: $gray-base;
    }
  }

  @include media('<sm') {
    .catalog-item-panel {
      &__body {
        width: 290px;
        flex-shrink: 0;
      }

      .btn {
        max-width: 200px;
        margin-left: auto;
        margin-top: 10px;
        width: 100%;
        display: block;
      }
    }
  }
}

.catalog-grid-item {
  .catalog-item-panel {
    margin: 0 auto;

    &.row > * {
      width: 100%;
    }

    &__img {
      padding: 0;
    }

    .media-body {
      vertical-align: middle;
    }

    .cost-row > * {
      display: block;
    }

    .media-footer > * {
      width: 100%;
    }
  }
}

/**        ¬ catalog front page         **/
.catalog-front {
  &:before,
  &:after {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: -1px 0 0 -1px;

  &:nth-last-of-type(n+2) {
    border-bottom: 1px solid $border-light;
  }
  overflow: hidden;

  @include media('>sm') {
    &:nth-of-type(1) {
      h2 {
        margin-top: 0;
      }
    }
  }

  @include media('>sm', '<md') {
    &-inner {
      margin-top: 50/16 + em;
    }
  }

  &__title {
    margin-top: 1em;
    margin-bottom: 27/40 + em;
    line-height: 1;
    white-space: nowrap;
  }

  > [class^=col] {
    flex: 0 0 auto;
    position: relative;
    left: 1px;
    top: 1px;
    padding: 0;
    border: 1px solid $border-light;
    border-width: 0 1px 1px 0;

    .list-default {
      margin-bottom: 0;

      &:nth-of-type(1) {
        margin-top: 8/16 + em;
      }

      > li:last-child {
        margin-bottom: 0;
      }
    }
  }

  &:nth-last-of-type(n+2) {
    > [class^=col] {
      padding-bottom: 40/16 + em;
    }
  }

  @include media('<sm') {
    &:nth-last-of-type(1) {
      > [class^=col]:nth-last-of-type(n+2) {
        padding-bottom: 40/16 + em;
      }
    }
  }
}

/**        ¬ catalog details         **/
.main-product {
  &__carousel-row {
    position: relative;
    background-color: #fff;
    overflow: hidden;

    .control-carousel {
      margin: 0;
      height: 0;

      .btn-control {
        position: absolute;
        top: 50%;
        margin-top: -24px;
        z-index: 10000;
      }
      .prev {
        left: 0;
      }
      .next {
        right: 0;
      }
    }
  }

  &-sidebar {
    margin-top: -6px;
    h4 {
      margin-top: 0;
    }

    > section {

      &:nth-last-child(n+2) {
        margin-bottom: 40/16 + em;
      }
    }
  }

  &__price {
    padding: 25/16 + em 30px 30/16 + em;
    margin: 0;
    background-color: $brand-light;
    overflow: auto;

    .cost-current {
      font-size: 40/20 + em;

      + .cost-total {
        font-size: 40/20 + em;
      }
    }

    .cost-row-caption {
      font-size: 20/16 + em;
      font-weight: 700;
    }

    .cost-row {
      &:before,
      &:after {
        display: none;
      }
      margin-bottom: 40/20 + em;
      display: flex;
      align-items: center;
      align-content: center;
      line-height: 1;
    }

    .media-left {
      vertical-align: middle;
      padding-right: 30px;
    }
  }

  .label-title {
    margin-bottom: 8px;
  }

  &-pallete {
    margin-left: -15px;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;

    .check-color {
      &:nth-child(1) .input-color {background-color: #ff4040;}
      &:nth-child(2) .input-color {background-color: #ffeec5;}
      &:nth-child(3) .input-color {background-color: #c5d6ff;}
      &:nth-child(4) .input-color {background-color: #b9b9b9;}
    }
  }

  @include media('<sm') {
    &__price {
      margin-bottom: 80/16 + em;
    }
  }
}

/**        ¬ catalog compare         **/
.compare-container {
  max-width: 1170px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto 80px;

  @include media('<380px') {
    padding-left: 0;
  }
}

.compare-table {
  display: table;
  table-layout: fixed;
  min-width: 380px;
  width: 100%;

  &-row {
    display: table-row;
    margin: 0;

    > * {
      display: table-cell;
      float: none;
      padding-left: 15/1140*100%;
      padding-right: 15/1140*100%;

      @include media('<640px') {
        width: 50%;
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
  }
}

.compare-top {
  &__btn-group {
    > * {
      padding: 0;
    }
    .btn-close {
      width: 100%;
      height: 50px;
      transform: none;

      &__bar {
        left: 50%;
        width: 1px;
        height: 19px;
        margin: 0;
        margin-top: 15px;
        transform: rotate(45deg);

        &:last-child {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__header {
    background-color: $brand-light;
    > * {
      min-height: 45px;
      padding: 10px 15px;
    }

    h3 {
      margin: 0;
      line-height: 25px;
    }
  }

  .item-thumb {
    position: relative;
    max-width: 260px;
    width: 100%;
    padding: 0 10px;
    margin: 30px auto;
    overflow: visible;
  }
}

.compare-section {
  margin-bottom: 20px;

  &__header {
    padding: 0 0 15px;

    @include media('<380px') {
      padding-left: 15px;
    }

    h3 {
      margin: 0;
      line-height: 1;
      color: $gray-light;
    }
  }

  .compare-table-row {
    > * {
      padding-top: 15px;
      border-top: 1px solid $border-color;
    }
  }
}

.compare-bottom {
  .compare-table-row > * {
    padding: 35px 25/1140*100%;
    background-color: $brand-light;
  }
}

.compare-raiting {
  margin-bottom: 5px;
}

/**        ¬ product tabs         **/
.product-tabs {
  margin-top: 50px;

  .nav-tabs {
    border-bottom: 1px solid $brand-gray;

    > li {
      margin-left: -1px;
      margin-right: 0;

      &:first-child {
        margin-left: 0;
      }

      > a {
        padding: 17px 30px;
        margin-right: 0;
        font-family: $font-raleway;
        font-weight: 700;
        line-height: 1;
        letter-spacing: $ls-regular;
        text-transform: uppercase;
        border-color: $brand-gray;
        border-radius: 0;
        @extend %transition;

        &:hover {
          color: #fff;
          background-color: $gray-dark;
          border-color: $gray-dark;
        }
      }

      // Active state, and its :hover to override normal :hover
      &.active > a {
        &,
        &:hover,
        &:focus {
          color: #fff;
          background-color: $gray-darker;
          border-color: $gray-darker;
        }
      }
    }
  }

  .tab-content {
    padding: 50px 30px;
    border: 1px solid $brand-gray;
    border-top-width: 0;
  }

  .tab-pane p:last-child {
    margin-bottom: 0;
  }

  .comment-form button[type="submit"] {
    margin-top: 15px;
  }

  @include media('<xs') {
    .nav-tabs {
      > li {
        float: none;
        margin-left: 0;
      }

      > li > a {
        padding: 17px 15px;
      }
    }

    .tab-content {
      padding: 30px 15px 50px;
    }

    .post-comment {
      padding: 30px 15px;
      margin: -30px -15px 30px;
    }
  }
}

/**        ¬ catalog search switcher         **/
.catalog-search-switcher {
  position: relative;
  padding: 8px 0;
  margin-bottom: 50px;
  border: 1px solid $border-lighter;
  border-width: 1px 0 1px 0;

  > * {
    vertical-align: middle;
  }

  .media-object {
    width: 115px;
    margin-left: 0;
  }

  .count-search,
  .input-search-result {
    font-weight: 700;
    line-height: inherit;
  }

  .result-clean {
    padding: 0;
    cursor: pointer;
    @extend %transition;

    &:hover {
      color: $text-color;
    }
  }
}

/**        ¬ catalog sort panel         **/
.catalog-sort-panel {
  position: relative;
  padding: 0 60px;
  margin-bottom: 50px;

  &__list {
    display: flex;
    align-items: center;
    align-content: center;
    margin-right: -60px;

    > li {
      margin-right: 60px;
      flex-shrink: 0;
    }

    > li > a {
      font-size: 40px;
      line-height: 1;
      color: $brand-gray;
      @extend %transition;

      &:hover {
        color: $text-color;
      }
    }

    > li.active > a {
      color: $text-color;
    }

    @include media('<xs') {
      > li > a {
        font-size: 40/560*100vw;
      }
    }
  }

  .btn-control {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -24px;

    &.next {
      left: auto;
      right: 0;
    }
  }
}

/**        ¬ refine search         **/
.refine-search {
  margin-top: -6px;
  margin-bottom: 40px;

  h4 {
    margin-top: 0;
  }
}

/**        ¬ control bar         **/
.control-bar {
  display: flex;
  align-items: flex-end;
  margin-bottom: 50px;

  &-sort {
    margin: 0 -10px;

    .label-control {
      display: block;
      color: $text-color;
    }

    .form-group {
      padding: 0 10px;
    }
  }

  &-grid {
    display: flex;
    justify-content: flex-end;

    &__btn {
      display: block;
      width: 42px;
      height: 42px;
      padding: 11px;
      margin: 0 0 15px 10px;
      background-color: $brand-white;
      border: 1px solid $border-light;
      border-radius: 50%;
      text-align: center;
      font-size: 20px;

      .icon {
        display: block;
      }
    }

    > li {
      &.active {
        .control-bar-grid__btn {
          background-color: $gray-darker;
          color: #fff;
        }
      }
    }
  }
}

/**        ¬ catalog pagination         **/
.catalog-pagination {
  margin-top: 20px;

  .pagination-page {
    margin-top: 0;
  }
}
